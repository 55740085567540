<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">

    <Snackbar ref="snackbar"/>
    <Confirmation @action-delete="deleted()"/>

    <div class="mt-5">
      <div class="title black--text mb-2">List Category</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Cari Category . . ."
              autocomplete="off"
              v-model="searching"
              clearable
              color="#F05326"
              @click:clear="searching = '';"
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn 
              depressed
              @click="$router.push(`/category/create`)"
              elevation="0"
              color="#F05326"
              class="white--text text-capitalize ml-5">
              Add new category
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody">
          <v-data-table
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText : 'Data per halaman',
              'items-per-page-options': [5,10, 20, 50, 100, 200, 350]
            }"
            >
            <template slot="footer" slot-scope="{ props }">
              <Pagination 
                @reload="fetch(page)"
                @changeLimit="limit = $event; fetch()" 
                @changePage="page = $event; fetch($event)" 
                :model="props"/>
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <!-- <td class="text-start white">
                  <v-list-item class="pa-0">
                    <v-list-item-avatar v-if="props.item.picture" style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="props.item.picture">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                     <v-list-item-avatar v-else style="height: 42px; min-width: 42px; width: 42px; border: 3px solid rgb(255, 255, 255);background-color: white" size="40">
                      <v-img :src="require('@/assets/img/profile_picture.png')">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="#d31145">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </td> -->
                <td class="text-start white">
                  {{ props.item.title }}
                </td>
                <td class="text-start white">
                  {{ props.item.topics.length }}
                </td>
                <td class="text-center white">
                  <p v-if="props.item.is_paid">Paid</p>
                  <p v-else>Free</p>
                </td>
                <!-- <td class="text-center white">
                  {{ props.item.last_login | datetimeFull }}
                </td> -->
                <td class="text-center white">
                  <v-btn
                    icon
                    :to="`/category/update/${props.item.id}`">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="confirmation(props.item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>                  
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>

  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import GridTable from '@/components/GridTable'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'

export default {
    components: { GridTable, Pagination, Breadcrumbs, Snackbar, Confirmation },
  data() {
    return {
      title:"List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0
      },
      headers: [
        // { text: 'Image',align: 'start',value: 'username',sortable: false },
        { text: 'Category Name',align: 'start',value: 'title',sortable: false },
        { text: 'Total Topic',align: 'start',value: 'topics.length',sortable: false },
        { text: 'Type',align: 'center',value: 'status',sortable: false },
        // { text: 'Last Access',align: 'center',value: 'last_access',sortable: false },
        { text: '',align: 'end',value: 'action',sortable: false }
      ],
      items:{},
      searching:"",
      process: {
        run: false
      },
      category_id:null,
    }
  },
  watch:{
    'selected.pagination': function(val) {
      this.fetch(val+1)
    },
  },
  created(){
    this.fetch()
  },
  mounted(){},
  methods:{
    changePage (event) {
      if (event === undefined) {
        this.selected.pagination = 0
      } 
    },
    filter(){
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging){
      let _self = this;
      _self.process.run = true

      await get(`api/v1/group-topic/list`,{
        params:{
          limit: this.limit,
          page: paging,
        }
      }).then(response => {
        let res = response.data
        this.items = res.data
        this.$store.state.process.run = false
        this.totalData = res.data.total
      })
    },
    detail(item){
      this.$router.push(`/users/update/${item.id}`)
    },
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.id_topic = id
    },
    confirmation(id){
      this.$store.state.dialog.confirmDelete = true
      this.category_id = id
    },
    async deleted(){
      this.$store.state.process.run = true
      await destroy(`api/v1/group-topic/delete/${this.category_id}`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("#4CAF50", `Category Deleted Succesfully`);
          // this.fetchDetailCourse()
          this.fetch()
        }else{
          this.$store.state.dialog.confirmDelete = false
          this.$store.state.process.run = false
          this.$refs.snackbar.open("error", `Category Deleted Failed`);
          // this.fetchDetailCourse()
          this.fetch()
        }
      })
    },
  }
}
</script>

<style>

</style>